import React from "react";
import "./mobileMenu.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import CustomBtn from "../primitive/CustomBtn";

const MobileMenu = () => {
  const location = useLocation();

  const navigate = useNavigate();
  const navigateToContactUs = () => {
    navigate("/contact-us");
  };

  // const openPopup = () => {
  //   const popupWidth = 400;
  //   const popupHeight = 400;
  //   const popupFeatures = `width=${popupWidth},height=${popupHeight},scrollbars=no,resizable=no`;

  //   window.open(
  //     "https://medium.com/@coolidgesolutions",
  //     "popup",
  //     popupFeatures
  //   );
  // };
  const isBlogPostDetails = location.pathname.startsWith("/blog");

  return (
    <div>
      <div id="nav-bar">
        <input type="checkbox" className="check" id="checked" />
        <label className="menu-btn" htmlFor="checked">
          <span
            className={
              location.pathname === "/privacy-policy" || isBlogPostDetails
                ? "bg-gray-700 bar_nav top"
                : "bg-white bar_nav top"
            }
          ></span>
          <span
            className={
              location.pathname === "/privacy-policy" || isBlogPostDetails
                ? "bg-gray-700 bar_nav middle"
                : "bg-white bar_nav middle"
            }
          ></span>
          <span
            className={
              location.pathname === "/privacy-policy" || isBlogPostDetails
                ? "bg-gray-700 bar_nav bottom"
                : "bg-white bar_nav bottom"
            }
          ></span>
        </label>

        <nav className="drawer-menu">
          <ul>
            <li>
              <Link className="font-source-sans-pro-light text-sm" to="/">
                Home
              </Link>
            </li>
            <li>
              <Link
                className="font-source-sans-pro-light text-sm"
                to="/strategy-and-risk"
              >
                Strategy and Risk Management
              </Link>
            </li>
            <li>
              <Link
                className="font-source-sans-pro-light text-sm"
                to="/cybersecurity-transformation"
              >
                Cybersecurity Transformation
              </Link>
            </li>
            <li>
              <Link
                className="font-source-sans-pro-light text-sm"
                to="/cybersecurity-testing"
              >
                Cybersecurity Testing
              </Link>
            </li>
            <li>
              <Link
                className="font-source-sans-pro-light text-sm"
                to="/data-analytics"
              >
                Data Analytics
              </Link>
            </li>
            <li>
              <Link className="font-source-sans-pro-light text-sm" to="/blog">
                Articles
              </Link>
              {/* <Link
                className="font-source-sans-pro-light text-sm"
                onClick={openPopup}
              >
                Article
              </Link> */}
            </li>
            <li>
              <div className="pt-10">
                <CustomBtn
                  onClick={navigateToContactUs}
                  text="Contact Us"
                  green="none"
                  boxHover="0 0 0 0 #000000, inset 6em 4.5em 0 0 #000000"
                  boxBeforeHover="0.3em 0.3em 0 0 #000000, inset 0.3em 0.3em 0 0 #000000"
                  hoverTextColor="#FFFFFF"
                  textColorBeforHover="#000000"
                  hoverBackgroundColor="#ffffff"
                  unHoverBackgroundColor="#ffffff"
                  paddingTop="1.2rem"
                  paddingBottom="1.2rem"
                  paddingLeft="2rem"
                  paddingRight="2rem"
                />
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default MobileMenu;
