const ReduceText = (text) => {
  const totalLenght = 40;
  if (text?.length > totalLenght) {
    const allTakenTxt = text.substring(0, totalLenght);
    return `${allTakenTxt}...`;
  }
  return text;
};

export default ReduceText;
