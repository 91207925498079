import React from "react";
import "./index.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import StrategyAndRisk from "./pages/StrategyAndRisk";
import CybersecurityTransformation from "./pages/CybersecurityTransformation";
import CybersecurityTesting from "./pages/CybersecurityTesting";
import DataAnalytics from "./pages/DataAnalytics";
import RequestDemo from "./pages/RequestDemo";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Blog from "./pages/Blog";
import BlogPostDetails from "./pages/BlogPostDetails";
import ErrorPage from "./pages/ErrorPage";
import ScrollToTop from "./components/sections/ScrollToTop";
import { Helmet, HelmetProvider } from "react-helmet-async";

function App() {
  return (
    <HelmetProvider>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Helmet>
                  <title>
                    Empowering Your Business with Solutions in Cybersecurity and
                    Data Analytics | Coolidge Solutions
                  </title>
                </Helmet>
                <Home />
              </>
            }
          />
          <Route
            path="/strategy-and-risk"
            element={
              <>
                <Helmet>
                  <title>
                    Strategy and Risk Management | Coolidge Solutions
                  </title>
                </Helmet>
                <StrategyAndRisk />
              </>
            }
          />
          <Route
            path="/cybersecurity-transformation"
            element={
              <>
                <Helmet>
                  <title>
                    Cybersecurity Transformation | Coolidge Solutions
                  </title>
                </Helmet>
                <CybersecurityTransformation />
              </>
            }
          />
          <Route
            path="/cybersecurity-testing"
            element={
              <>
                <Helmet>
                  <title>Cybersecurity Testing | Coolidge Solutions</title>
                </Helmet>
                <CybersecurityTesting />
              </>
            }
          />
          <Route
            path="/data-analytics"
            element={
              <>
                <Helmet>
                  <title>Data Analytics | Coolidge Solutions</title>
                </Helmet>
                <DataAnalytics />
              </>
            }
          />
          <Route
            path="/contact-us"
            element={
              <>
                <Helmet>
                  <title>
                    Empowering Your Business with Solutions in Cybersecurity and
                    Data Analytics | Coolidge Solutions
                  </title>
                </Helmet>
                <RequestDemo />
              </>
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <>
                <Helmet>
                  <title>
                    Empowering Your Business with Solutions in Cybersecurity and
                    Data Analytics | Coolidge Solutions
                  </title>
                </Helmet>
                <PrivacyPolicy />
              </>
            }
          />
          <Route
            path="*"
            element={
              <>
                <Helmet>
                  <title>
                    Empowering Your Business with Solutions in Cybersecurity and
                    Data Analytics | Coolidge Solutions
                  </title>
                </Helmet>
                <ErrorPage />
              </>
            }
          />
          <Route
            path="/blog"
            element={
              <>
                <Helmet>
                  <title>Blog Post | Coolidge Solutions</title>
                </Helmet>
                <Blog />
              </>
            }
          />
          <Route
            path="/blog/:id/:title"
            element={
              <>
                <Helmet>
                  <title>Blog Post | Coolidge Solutions</title>
                </Helmet>
                <BlogPostDetails />
              </>
            }
          />
        </Routes>
      </Router>
    </HelmetProvider>
  );
}

export default App;
