import React from "react";
import data_analyrics_hero_img from "../../assets/images/data-analyrics-hero-img.png";
import data_analytics_center_img from "../../assets/images/data-analytics-center-img.png";

const DataAnalyticsHero = () => {
  return (
    <div>
      <div className=" flex flex-col-reverse items-center space-y-6 lg:space-y-0 lg:flex-row lg:py-20 lg:space-x-20">
        <div className="w-full lg:w-[55%]">
          <img src={data_analyrics_hero_img} className="w-full" alt="" />
        </div>
        <div className="px-1.5 nd:px-0 w-full lg:w-[45%] space-y-2.5 lg:space-y-4 pb-12">
          <div className="flex justify-center lg:justify-start">
            <h1 className="max-w-4xl text-3xl lg:text-7xl text-white text-center lg:text-left font-source-sans-pro-light">
              Data
              <br /> Analytics
            </h1>
          </div>
          <div className="flex justify-center lg:justify-start">
            <p className="max-w-xs lg:max-w-sm text-lg lg:text-xl lg:leading-8 text-Nobel text-center lg:text-left font-source-sans-pro-extralight">
              Harnessing data for business growth through analytics.
            </p>
          </div>
          <p className="lg:max-w-md text-base lg:text-[18px] lg:leading-8  text-white text-center lg:text-left font-source-sans-pro-extralight px-[1rem] md:px-6 lg:px-0">
            We are in a data-driven world, our data analytics services provide invaluable insights. Our team of experts collaborates closely with your organisation to conduct thorough assessments of your data infrastructure, sources, and data quality, ensuring optimal data utilisation.
            
          </p>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center px-1.5 nd:px-0 space-y-3 lg:space-y-6">
        <p className="lg:max-w-4xl text-base lg:text-[16px] lg:leading-7  text-white text-center font-source-sans-pro-extralight px-[1rem] md:px-6 lg:px-0 py-4 lg:py-0">
          We collaborate closely with your organisation to identify your unique data needs and goals, ensuring our solutions align with your strategic objectives. We start by assessing your existing data infrastructure, sources, and quality to ensure accurate and reliable data. We then employ advanced analytics techniques, including predictive modelling, machine learning, and data visualisation, to extract meaningful patterns, trends, and correlations from your data. 

        </p>
        <div className="flex justify-center px-[1rem] md:px-6 lg:px-0">
            <img src={data_analytics_center_img} className="lg:w-[70%]" alt="" />
        </div>
      </div>
    </div>
  );
};

export default DataAnalyticsHero;
