import React from "react";
import strategy_and_risk_hero_img from "../../assets/images/strategy-and-risk-hero-img.png";

const StrategyAndRiskHero = () => {
  return (
    <div className="py-4 md:py-12 space-y-6">
      <div className="flex justify-center">
        <h1 className="max-w-4xl text-center text-3xl md:text-7xl text-white font-source-sans-pro-light">
          Strategy and Risk
        </h1>
      </div>
      <p className="text-lg md:text-2xl text-white text-center font-source-sans-pro-extralight">
        Empowering your business through strategic insights and risk management
      </p>
      <div className="flex justify-center">
        <img
          src={strategy_and_risk_hero_img}
          className="w-[80%] md:w-[55%]"
          alt="home hero"
        />
      </div>
      <div className="flex justify-center">
        <p className="max-w-4xl text-sm md:text-base text-white text-center font-source-sans-pro-light px-[1rem] md:px-6 lg:px-0">
          In the ever-changing digital world, a resilient cybersecurity strategy is indispensable for safeguarding your organisation against a constantly expanding array of threats. Our all-encompassing strategy and risk management services deliver the expertise and counsel necessary to navigate this intricate terrain and guarantee the durability of your digital assets. We initiate the process by performing a meticulous evaluation of your existing cybersecurity strategy, pinpointing potential vulnerabilities and enhancement opportunities. Our adept team scrutinises your systems, networks, policies, and protocols to attain a comprehensive insight into your organisation's distinct risk management plan.
        </p>
      </div>
    </div>
  );
};

export default StrategyAndRiskHero;