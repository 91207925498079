import React from "react";
import data_analyrics_body_img from "../../assets/images/data_analyrics_body_img.png";
import CustomBtn from "../primitive/CustomBtn";
import { useNavigate } from "react-router-dom";

const DataAnalyticsBody = () => {
  const navigate = useNavigate();

  const navigateToContactUs = () => {
    navigate("/contact-us");
  };
  return (
    <div className="cybersecurity_testing_body_bg_img pb-20 md:pb-32">
      <div className=" flex flex-col-reverse items-center space-y-6 md:space-y-0 md:flex-row md:py-20 md:space-x-20">
        <div className="w-full md:w-[45%] py-4 md:py-0">
          <img src={data_analyrics_body_img} className="w-full" alt="" />
        </div>
        <div className="px-1.5 nd:px-0 w-full md:w-[55%] space-y-6 md:space-y-14 md:pb-12">
          <p className="md:max-w-xl text-base md:text-[16px] md:leading-8  text-white text-center md:text-left font-source-sans-pro-extralight px-[1rem] md:px-6 lg:px-0">
            From customer behaviour analysis and market segmentation to operational optimization and risk assessment, we help you unlock valuable insights that can guide your strategic initiatives. We go beyond just delivering insights; we provide actionable recommendations and strategic guidance to drive tangible results. Our team works closely with you to understand the implications of the data insights and translate them into practical business strategies. Furthermore, we ensure data security and compliance throughout the analytics process. We adhere to industry best practices and regulatory requirements to protect the confidentiality, integrity, and privacy of your data.
          </p>
          <div className="py-4 md:py-0 flex justify-center md:justify-start">
            <CustomBtn
              onClick={navigateToContactUs}
              text="Request a Service"
              green="none"
              boxHover="0 0 0 0 #000000, inset 6em 4.5em 0 0 #000000"
              boxBeforeHover="0.3em 0.3em 0 0 #FFFFFF, inset 0.3em 0.3em 0 0 #FFFFFF"
              hoverTextColor="#FFFFFF"
              textColorBeforHover="#000000"
              hoverBackgroundColor="#FFFFFF"
              unHoverBackgroundColor="#FFFFFF"
              paddingTop="1.2rem"
              paddingBottom="1.2rem"
              paddingLeft="2rem"
              paddingRight="2rem"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center px-1.5 nd:px-0 space-y-3 md:space-y-20">
        <p className="md:max-w-3xl text-base md:text-[16px] md:leading-7  text-white text-center font-source-sans-pro-extralight px-[1rem] md:px-6 lg:px-0">
          With our data analytics services, you gain a competitive advantage by
          leveraging the power of data to uncover hidden opportunities, optimize
          operations, and enhance decision-making. Empower your organization to
          thrive in the data-driven era by partnering with us to unlock the true
          potential of your data.
        </p>
      </div>
    </div>
  );
};

export default DataAnalyticsBody;
