import React from "react";
import cybersecurity_transformation_body_img from "../../assets/images/cybersecurity-transformation-body-img.png";
import CustomBtn from "../primitive/CustomBtn";
import { useNavigate } from "react-router-dom";

const CybersecurityTransformationBody = () => {
  const navigate = useNavigate();

  const navigateToContactUs = () => {
    navigate("/contact-us");
  };

  return (
    <div className="relative">
      <div className="bg-DarkJungleGreen xl:pt-28 xl:pb-20 ">
        <div className="flex flex-col-reverse space-y-6 xl:space-y-0 xl:flex-row">
          <div className="w-full xl:w-5/12 py-8 xl:py-0">
            <div className="w-[90%]">
              <img
                src={cybersecurity_transformation_body_img}
                className="w-[20rem] lg:w-[35rem] xl:absolute xl:bottom-10"
                alt=""
              />
            </div>
          </div>
          <div className="w-full xl:w-7/12 px-[1.5rem] xl:px-0">
            <p className="xl:max-w-xl text-sm xl:text-[18px] xl:leading-8 text-white  text-center xl:text-left font-source-sans-pro-extralight px-[1rem] md:px-6 lg:px-0s">
              Furthermore, we take pride in empowering your workforce to serve
              as your organisation's primary line of defence. Our engaging
              training programs educate employees on best practices, heightening
              awareness about common attack vectors like phishing attempts and
              social engineering. Through this culture of cybersecurity
              awareness, your organisation transforms into a formidable barrier
              against malicious actors. With our cybersecurity transformation
              services, you can enjoy peace of mind, secure in the knowledge
              that your digital assets are protected by a multi-layered defence
              strategy. By proactively adapting to the ever-evolving threat
              landscape, you can concentrate on fostering innovation and growth,
              all while maintaining confidence in the resilience of your
              cybersecurity framework.
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col xl:flex-row">
        <div className="w-full xl:w-5/12"></div>
        <div className="w-full xl:w-7/12 py-20 xl:py-24 container mx-auto">
          <div className="flex justify-center xl:justify-start">
            <CustomBtn
              onClick={navigateToContactUs}
              text="Request a Service"
              green="none"
              boxHover="0 0 0 0 #000000, inset 6em 4.5em 0 0 #000000"
              boxBeforeHover="0.3em 0.3em 0 0 #000000, inset 0.3em 0.3em 0 0 #000000"
              hoverTextColor="#FFFFFF"
              textColorBeforHover="#000000"
              paddingTop="1.2rem"
              paddingBottom="1.2rem"
              paddingLeft="2rem"
              paddingRight="2rem"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CybersecurityTransformationBody;

