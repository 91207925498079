import React from "react";
import Navbar from "../components/connected/Navbar";
import CybersecurityTestingHero from "../components/sections/CybersecurityTestingHero";
import CybersecurityTestingBody from "../components/sections/CybersecurityTestingBody";
import Footer from "../components/sections/Footer";
import ScrollToTopBtn from "../components/connected/ScrollToTopBtn";

const CybersecurityTesting = () => {
  return (
    <div>
      <div className="cybersecurity_testing_bg">
        <Navbar />
        <CybersecurityTestingHero />
      </div>
      <CybersecurityTestingBody />
      <Footer />
      <ScrollToTopBtn />
    </div>
  );
};

export default CybersecurityTesting;
