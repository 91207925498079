import React, { useState } from "react";
import cs_logo from "../../assets/icons/cs-logo.svg";
import request_demo_icon_1 from "../../assets/icons/request-demo-icon1.svg";
import request_demo_icon_2 from "../../assets/icons/request-demo-icon2.svg";
import iconmetro_checkmark from "../../assets/icons/iconmetro-checkmark.svg";
import { Link, useNavigate } from "react-router-dom";
import CustomBtn from "../primitive/CustomBtn";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const RequestDemoForm = () => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    country: "",
    comment: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isSubmitDisabled =
    !formData.first_name ||
    !formData.last_name ||
    !formData.email ||
    !formData.phone ||
    !formData.country ||
    !formData.comment;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const endpoint = "https://blog.coolidgesolutions.com/api/demorequests";
    // const endpoint = "https://admin.coolidgesolutions.com/api/demorequests";

    fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setIsSubmitting(false);
          setFormData({
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            country: "",
            comment: "",
          });
          toast("Data submited successfully!");
        }
      })
      .catch((error) => {
        console.error("Error sending data:", error);
      });
  };

  const navigate = useNavigate();

  const navigateToHome = () => {
    navigate("/");
  };

  return (
    <div>
      <div className="lg:h-[120vh]">
        <div className="flex flex-col space-y-6 lg:space-y-0 lg:flex-row h-full">
          <div className="px-[1.5rem] lg:px-0 py-10 md:py-20 lg:py-3 w-full lg:w-1/2 request_demo_bg">
            <div className="flex justify-between items-center lg:pr-[9%]">
              <button onClick={navigateToHome}>
                <img
                  src={cs_logo}
                  className="pt-3 pl-4 lg:pl-[8.5rem]"
                  alt=""
                />
              </button>
              <Link
                to="/"
                className="raise pt-2 text-white font-source-sans-pro-extralight text-base"
              >
                Go Home
              </Link>
            </div>
            <div className="flex flex-col lg:flex-row pt-12 lg:pt-28">
              <div className="w-full lg:w-4/12"></div>
              <div className="w-full lg:w-7/12">
                <div className="space-y-8 mb-12">
                  <h1 className="max-w-4xl text-3xl lg:text-5xl text-white font-source-sans-pro-light">
                    Request a service
                  </h1>
                  <p className="text-Nobel">
                    Request a service now, and discover the difference of
                    working with Coolidge Solutions.
                  </p>
                  <div className="space-y-6">
                    <div className="flex items-start space-x-2">
                      <div>
                        <img
                          src={iconmetro_checkmark}
                          className="pt-2"
                          alt=""
                        />
                      </div>
                      <p className="font-source-sans-pro-light text-white">
                        The variety of services available for request is
                        astounding. From Strategy and Risk Management to Data
                        analytics amongst many others.
                      </p>
                    </div>
                    <div className="flex items-start space-x-2">
                      <div>
                        <img
                          src={iconmetro_checkmark}
                          className="pt-2"
                          alt=""
                        />
                      </div>
                      <p className="font-source-sans-pro-light text-white">
                        Access to an attractive choice of competitive pricing
                        and cost-effective models.
                      </p>
                    </div>
                    <div className="flex items-start space-x-2">
                      <div>
                        <img
                          src={iconmetro_checkmark}
                          className="pt-2"
                          alt=""
                        />
                      </div>
                      <p className="font-source-sans-pro-light text-white">
                        Access reliability. We provide high quality solutions
                        and deliver on promises.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="flex flex-row items-center space-x-4">
                  <div className="w-48 bg-white bg-opacity-5 rounded-3xl bg-transparent px-4 pt-2 pb-6 space-y-3">
                    <div className="flex justify-end">
                      <img
                        src={request_demo_icon_1}
                        className="w-14 h-14 border border-white p-3 rounded-full"
                        alt=""
                      />
                    </div>
                    <p className="text-white text-sm font-source-sans-pro-extralight">
                      We believe in transparency and open communication. You'll
                      always be informed and involved in the process.
                    </p>
                  </div>
                  <div className="w-48 bg-white bg-opacity-5 rounded-3xl bg-transparent px-4 pt-2 pb-6 space-y-3">
                    <div className="flex justify-end">
                      <img
                        src={request_demo_icon_2}
                        className="w-14 h-14 border border-white p-3 rounded-full"
                        alt=""
                      />
                    </div>
                    <p className="text-white text-sm font-source-sans-pro-extralight">
                      When you choose us, you choose a partner committed to your
                      success. We're with you every step of the way.
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-1/12"></div>
            </div>
          </div>
          <div className="px-[1.5rem] lg:px-0 py-10 md:pb-20 lg:py-0 w-full lg:w-1/2 flex flex-col lg:flex-row">
            <div className="w-full lg:w-[8.38%]"></div>
            <div className="w-full lg:w-[60%] pt-16 lg:pt-36">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-5 gap-x-6">
                <div className="w-full">
                  <div className="flex items-start text-xs text-StormDust mb-2">
                    <label htmlFor="firstName">First Name</label>
                    <span>&#42;</span>
                  </div>
                  <input
                    required
                    className="w-full bg-WhiteLilac rounded-lg border-[0.1px] border-VeryLightPurple p-3 focus:outline-none focus:border-VeryLightPurple focus:border-[1px] focus:ring-0"
                    type="text"
                    name="first_name"
                    id=""
                    value={formData.first_name}
                    onChange={handleChange}
                  />
                </div>
                <div className="w-full">
                  <div className="flex items-start text-xs text-StormDust mb-2">
                    <label htmlFor="lastName">Last Name</label>
                    <span>&#42;</span>
                  </div>
                  <input
                    required
                    className="w-full bg-WhiteLilac rounded-lg border-[0.1px] border-VeryLightPurple p-3 focus:outline-none focus:border-VeryLightPurple focus:border-[1px] focus:ring-0"
                    type="text"
                    name="last_name"
                    id=""
                    value={formData.last_name}
                    onChange={handleChange}
                  />
                </div>
                <div className="w-full">
                  <div className="flex items-start text-xs text-StormDust mb-2">
                    <label htmlFor="businessEmail">Business Email</label>
                    <span>&#42;</span>
                  </div>
                  <input
                    required
                    className="w-full bg-WhiteLilac rounded-lg border-[0.1px] border-VeryLightPurple p-3 focus:outline-none focus:border-VeryLightPurple focus:border-[1px] focus:ring-0"
                    type="email"
                    name="email"
                    id=""
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
                {/* <div className="w-full">
                <div className="flex items-start text-xs text-StormDust mb-2">
                  <label htmlFor="company">Company</label>
                  <span>&#42;</span>
                </div>
                <input
                  required
                  className="w-full bg-WhiteLilac rounded-lg border-[0.1px] border-VeryLightPurple p-3 focus:outline-none focus:border-VeryLightPurple focus:border-[1px] focus:ring-0"
                  type="text"
                  name=""
                  id=""
                />
              </div> */}
                <div className="w-full">
                  <div className="flex items-start text-xs text-StormDust mb-2">
                    <label htmlFor="phone">Phone</label>
                    <span>&#42;</span>
                  </div>
                  <input
                    required
                    className="w-full bg-WhiteLilac rounded-lg border-[0.1px] border-VeryLightPurple p-3 focus:outline-none focus:border-VeryLightPurple focus:border-[1px] focus:ring-0"
                    type="text"
                    name="phone"
                    id=""
                    value={formData.phone}
                    onChange={handleChange}
                  />
                </div>
                {/* <div className="w-full">
                <div className="flex items-start text-xs text-StormDust mb-2">
                  <label htmlFor="employees">Employees</label>
                  <span>&#42;</span>
                </div>
                <input
                  required
                  className="w-full bg-WhiteLilac rounded-lg border-[0.1px] border-VeryLightPurple p-3 focus:outline-none focus:border-VeryLightPurple focus:border-[1px] focus:ring-0"
                  type="text"
                  name=""
                  id=""
                />
              </div> */}
                <div className="w-full">
                  <div className="flex items-start text-xs text-StormDust mb-2">
                    <label htmlFor="country">Country</label>
                    <span>&#42;</span>
                  </div>
                  <input
                    required
                    className="w-full bg-WhiteLilac rounded-lg border-[0.1px] border-VeryLightPurple p-3 focus:outline-none focus:border-VeryLightPurple focus:border-[1px] focus:ring-0"
                    type="text"
                    name="country"
                    id=""
                    value={formData.country}
                    onChange={handleChange}
                  />
                </div>
                <div className="w-full sm:col-span-2">
                  <label
                    htmlFor="comments"
                    className=" text-xs text-StormDust mb-2"
                  >
                    Comments:
                  </label>
                  <textarea
                    required
                    className="w-full bg-WhiteLilac rounded-lg border-[0.1px] border-VeryLightPurple p-3 focus:outline-none focus:border-VeryLightPurple focus:border-[1px] focus:ring-0"
                    type="text"
                    name="comment"
                    id=""
                    rows="4"
                    value={formData.comment}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>
              <div className="space-y-16">
                <div className="mt-16">
                  <ToastContainer />
                  <CustomBtn
                    className={isSubmitDisabled && "cursor-not-allowed"}
                    disabled={isSubmitDisabled}
                    onClick={handleSubmit}
                    text={isSubmitting ? "Submitting..." : "Submit"}
                    boxHover="0 0 0 0 #000000, inset 6em 4.5em 0 0 #000000"
                    boxBeforeHover="0.3em 0.3em 0 0 #000000, inset 0.3em 0.3em 0 0 #000000"
                    hoverTextColor="#FFFFFF"
                    textColorBeforHover="#000000"
                    paddingTop="1.2rem"
                    paddingBottom="1.2rem"
                    paddingLeft="2rem"
                    paddingRight="2rem"
                  />
                </div>
                <p className="text-xs max-w-sm font-source-sans-pro-extralight">
                  By clicking Submit, I agree to the use of my personal data in
                  accordance with Coolidge Solutions{" "}
                  <Link
                    className="font-source-sans-pro-bold cursor-pointer hover:underline transition-all duration-200"
                    to="/privacy-policy"
                  >
                    Privacy Policy
                  </Link>
                  . Coolidge Solutions will not sell, trade, lease, or rent your
                  personal data to third parties.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestDemoForm;
