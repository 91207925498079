import React from "react";
import Navbar from "../components/connected/Navbar";
import DataAnalyticsHero from "../components/sections/DataAnalyticsHero";
import DataAnalyticsBody from "../components/sections/DataAnalyticsBody";
import Footer from "../components/sections/Footer";
import ScrollToTopBtn from "../components/connected/ScrollToTopBtn";

const DataAnalytics = () => {
  return (
    <div>
      <div className="data_analytics_bg">
        <Navbar />
        <DataAnalyticsHero />
      </div>
      <DataAnalyticsBody />
      <Footer />
      <ScrollToTopBtn />
    </div>
  );
};

export default DataAnalytics;
