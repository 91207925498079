import React, { useEffect } from "react";
import Navbar from "../components/connected/Navbar";
import HomeHero from "../components/sections/HomeHero";
import WhyWeShouldBeYourPartner from "../components/sections/WhyWeShouldBeYourPartner";
import Footer from "../components/sections/Footer";
import AboutUs from "../components/sections/AboutUs";
import WhatWeDo from "../components/sections/WhatWeDo";
import BlogPostSection from "../components/sections/BlogPostSection";
import ScrollToTopBtn from "../components/connected/ScrollToTopBtn";

const Home = () => {
  useEffect(() => {
    const hash = window.location.hash.substring(1);
    if (hash) {
      const section = document.getElementById(hash);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);
  return (
    <div>
      <div className="homePagebg">
        <Navbar />
        <HomeHero />
      </div>
      <WhyWeShouldBeYourPartner />
      <WhatWeDo />
      <BlogPostSection />
      <AboutUs />
      <Footer />
      <ScrollToTopBtn />
    </div>
  );
};

export default Home;
