import React from "react";
import cybersecurity_testing_hero_img from "../../assets/images/cybersecurity-testing-hero-img.png";

const CybersecurityTestingHero = () => {
  return (
    <div>
      <div className="relative  py-10 md:py-32">
        <div className="container mx-auto space-y-12 md:space-y-36">
          <div className="flex-flex-col space-y-4 items-end md:space-y-0 md:flex">
            <div className="w-full md:w-[63%] space-y-4 md:space-y-8">
              <div className="flex flex-col  items-center justify-center space-y-4 md:space-y-8 md:items-start">
                <h1 className="max-w-4xl text-3xl md:text-7xl text-white font-source-sans-pro-light text-center md:text-left">
                  Cybersecurity <br /> Testing
                </h1>
                <p className="max-w-xs md:max-w-sm text-lg md:text-xl md:leading-8 text-Nobel text-center md:text-left font-source-sans-pro-extralight">
                  Identify vulnerabilities in your systems or program before an attacker exploits them.

                </p>
              </div>
              <p className="md:max-w-xl text-sm md:text-[18px] md:leading-8  text-white  text-center md:text-left font-source-sans-pro-extralight">
                Cyber threats are relentless, perpetually evolving into more sophisticated and elusive forms. In response to this dynamic landscape, our cybersecurity testing services have emerged as a fortress of proactive defences. These defences are crafted and fortified by experts who specialise in comprehensive penetration testing, vulnerability assessments, and ethical hacking exercises, all meticulously designed to simulate real-world attacks. Cyber threats, in their relentless pursuit, have proven that no organisation is immune. That's why we employ cutting-edge methodologies to scrutinise your network, applications, and systems. We ensure no stone is left unturned in identifying potential entry points and vulnerabilities.
              </p>
            </div>
            <div className="w-full md:w-[37%] md:absolute md:right-0">
              <img
                src={cybersecurity_testing_hero_img}
                className="w-full"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CybersecurityTestingHero;
