import React from "react";
import Navbar from "../components/connected/Navbar";
import CybersecurityTransformationHero from "../components/sections/CybersecurityTransformationHero";
import CybersecurityTransformationBody from "../components/sections/CybersecurityTransformationBody";
import Footer from "../components/sections/Footer";
import ScrollToTopBtn from "../components/connected/ScrollToTopBtn";

const CybersecurityTransformation = () => {
  return (
    <div>
      <div className="cybersecurity_transformation_bg">
        <Navbar />
        <CybersecurityTransformationHero />
      </div>
      <CybersecurityTransformationBody />
      <Footer />
      <ScrollToTopBtn />
    </div>
  );
};

export default CybersecurityTransformation;
