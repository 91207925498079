import React from "react";
import card_bg from "../../assets/images/card_bg.png";
import card_one_img from "../../assets/images/card_one_img.png";
import card_two_img from "../../assets/images/card_two_img.png";
import card_three_img from "../../assets/images/card_three_img.png";
import card_four_img from "../../assets/images/card4.png";

const WhatWeDo = () => {
  return (
    <div id="our-services">
      <div className="what_we_do_section_bg py-10 md:py-24">
        <div className="container mx-auto  space-y-10 mb-16 sm:mb-20 md:mb-24">
          <h1 className="text-3xl md:text-[4rem] text-center text-black font-source-sans-pro-light">
            What We Do
          </h1>
          <p className="text-center font-source-sans-pro-extralight text-base md:text-xl">
            Our leading expertise allows us to provide strategic guidance that
            goes beyond the surface level. We delve deep into your data dynamics
            to uncover unique insights that drive innovation and competitive
            advantage. We stay ahead of the curve to ensure our cybersecurity
            services aligns with the demands of the digital age.
          </p>
        </div>
        <div className="grid-template-columns-big gap-y-16 sm:gap-y-28 gap-6 px-1.5 sm:px-10 mx-[1rem] md:mx-0">
          {/*  Strategy, Risk And Governance*/}
          <div className="relative">
            <img src={card_bg} className="w-full h-[580px]" alt="" />
            <div className="absolute -top-12">
              <div className="flex flex-col items-center justify-center h-[630px] p-6 space-y-4">
                <div className="w-full h-[210px]">
                  <img
                    src={card_one_img}
                    className="w-full h-full object-cover rounded-3xl"
                    alt=""
                  />
                </div>
                <div className="w-full h-[calc(630px-210px)]">
                  <div className="flex items-center justify-center w-full h-full">
                    <div className="bg-Magnolia border-[0.1px] border-Mauve pt-6 pb-5 px-5 rounded-3xl space-y-2 w-full h-full">
                      <h4 className="text-base md:text-xl ">
                        Strategy and Risk
                        <br />
                        Management
                      </h4>
                      <p className="font-source-sans-pro-extralight">
                        In today&apos;s evolving digital landscape, a robust
                        cybersecurity strategy is paramount to protect your
                        organization from an ever-growing array of threats. Our
                        comprehensive cybersecurity Strategy and Risk Management
                        services provide you with the expert guidance needed to
                        navigate this complex landscape and ensure the
                        resilience of your digital assets.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*   Cybersecurity Transformation*/}
          <div className="relative">
            <img src={card_bg} className="w-full h-[580px]" alt="" />
            <div className="absolute -top-12">
              <div className="flex flex-col items-center justify-center h-[630px] p-6 space-y-4">
                <div className="w-full h-[210px]">
                  <img
                    src={card_two_img}
                    className="w-full h-full object-cover rounded-3xl"
                    alt=""
                  />
                </div>
                <div className="w-full h-[calc(630px-210px)]">
                  <div className="flex items-center justify-center w-full h-full">
                    <div className="bg-Magnolia border-[0.1px] border-Mauve pt-6 pb-5 px-5 rounded-3xl space-y-2 w-full h-full">
                      <h4 className="text-base md:text-xl ">
                        Cybersecurity
                        <br />
                        Transformation
                      </h4>
                      <p className="font-source-sans-pro-extralight">
                        Are you ready to embark on a transformative journey to
                        safeguard your digital assets? Introducing our
                        cutting-edge cybersecurity transformation services – the
                        key to fortifying your organization against evolving
                        cyber threats.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*   Cybersecurity Testing */}
          <div className="relative">
            <img src={card_bg} className="w-full h-[580px]" alt="" />
            <div className="absolute -top-12">
              <div className="flex flex-col items-center justify-center h-[630px] p-6 space-y-4">
                <div className="w-full h-[210px]">
                  <img
                    src={card_three_img}
                    className="w-full h-full object-cover rounded-3xl"
                    alt=""
                  />
                </div>
                <div className="w-full h-[calc(630px-210px)]">
                  <div className="flex items-center justify-center w-full h-full">
                    <div className="bg-Magnolia border-[0.1px] border-Mauve pt-6 pb-5 px-5 rounded-3xl space-y-2 w-full h-full">
                      <h4 className="text-base md:text-xl ">
                        Cybersecurity
                        <br />
                        Testing
                      </h4>
                      <p className="font-source-sans-pro-extralight">
                        Are you confident in the strength of your
                        organization&apos;s cybersecurity defenses? Discover the
                        power of our cybersecurity testing services, designed to
                        expose vulnerabilities and fortify your digital
                        infrastructure against potential threats.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Data Analytics */}
          <div className="relative">
            <img src={card_bg} className="w-full h-[580px]" alt="" />
            <div className="absolute -top-12">
              <div className="flex flex-col items-center justify-center h-[630px] p-6 space-y-4">
                <div className="w-full h-[210px]">
                  <img
                    src={card_four_img}
                    className="w-full h-full object-cover rounded-3xl"
                    alt=""
                  />
                </div>
                <div className="w-full h-[calc(630px-210px)]">
                  <div className="flex items-center justify-center w-full h-full">
                    <div className="bg-Magnolia border-[0.1px] border-Mauve pt-6 pb-5 px-5 rounded-3xl space-y-2 w-full h-full">
                      <h4 className="text-base md:text-xl ">
                        Data
                        <br />
                        Analytics
                      </h4>
                      <p className="font-source-sans-pro-extralight">
                        Our data analytics services unlock the power of your
                        data, transforming it into actionable insights that
                        drive informed decision-making and fuel business growth.
                        In today&apos;s data-driven landscape, harnessing the
                        full potential of your data is crucial to gain a
                        competitive edge.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatWeDo;
