import React from "react";
import Navbar from "../components/connected/Navbar";
import StrategyAndRiskHero from "../components/sections/StrategyAndRiskHero";
import StrategyAndRiskBody from "../components/sections/StrategyAndRiskBody";
import Footer from "../components/sections/Footer";
import ScrollToTopBtn from "../components/connected/ScrollToTopBtn";

const StrategyAndRisk = () => {
  return (
    <div>
      <div className="strategy_and_risk_bg">
        <Navbar />
        <StrategyAndRiskHero />
      </div>
      <StrategyAndRiskBody />
      <Footer />
      <ScrollToTopBtn />
    </div>
  );
};

export default StrategyAndRisk;
