import React from "react";

const PrivacyPolicyBody = () => {
  return (
    <div className="container mx-auto py-6 md:py-16 space-y-4">
      <div className="space-y-2.5">
        <h5 className="font-bold text-xl md:text-2xl">Introduction</h5>
        <div className="space-y-1.5">
          <p>
            At Coolidge Solutions, we are committed to protecting the privacy
            and confidentiality of your personal information.
          </p>
          <p>
            This Privacy Policy outlines how we collect, use, and safeguard the
            information you provide when using our "Contact Us" form on our
            website. Please take a moment to read this policy to understand how
            we handle your data.
          </p>
        </div>
      </div>

      <div className="space-y-2.5">
        <h5 className="font-bold text-xl md:text-2xl">
          Information We Collect
        </h5>
        <div className="space-y-1.5">
          <p>
            When you use our "Contact Us" form, we may collect the following
            information:
          </p>
          <p>Name: We collect your name to address you personally.</p>
          <p>
            Email Address: We collect your email address to respond to your
            inquiries.
          </p>
          <p>
            Phone Number (optional): We may collect your phone number if you
            provide it, to facilitate communication.
          </p>
          <p>
            Message: The message you submit provides details about your inquiry
            or feedback.
          </p>
        </div>
      </div>
      <div className="space-y-2.5">
        <h5 className="font-bold text-xl md:text-2xl">Use of Information</h5>
        <div className="space-y-1.5">
          <p>
            We use the information collected through the "Contact Us" form for
            the following purposes:
          </p>
          <p>
            Responding to Inquiries: We use your contact details to respond to
            your inquiries or comments submitted through the form.
          </p>
          <p>
            Improving Our Services: Your feedback and inquiries help us enhance
            our products and services.
          </p>
          <p>
            Communication: With your consent, we may use your contact
            information to send you updates, promotions, or information related
            to our products or services.
          </p>
        </div>
      </div>
      <div className="space-y-2.5">
        <h5 className="font-bold text-xl md:text-2xl">Data Security</h5>
        <div className="space-y-1.5">
          <p>
            We take the security of your data seriously. We employ
            industry-standard security measures to protect your information from
            unauthorized access, disclosure, alteration, or destruction.
          </p>
          <h5 className="font-bold text-xl md:text-2xl">
            Sharing of Information
          </h5>
          <p>
            We do not sell, trade, or otherwise transfer your information to
            outside parties. However, we may share your information with trusted
            third parties who assist us in operating our website, conducting our
            business, or servicing you, provided that those parties agree to
            keep this information confidential.
          </p>
        </div>
      </div>
      <div className="space-y-2.5">
        <h5 className="font-bold text-xl md:text-2xl">Cookies</h5>
        <div className="space-y-1.5">
          <p>
            We may use cookies to collect and store information about your visit
            to our website. You can control the use of cookies through your
            browser settings.
          </p>
        </div>
      </div>
      <div className="space-y-2.5">
        <h5 className="font-bold text-xl md:text-2xl">Your Rights</h5>
        <div className="space-y-1.5">
          <p>You have the right to:</p>
          <p>Access: Request access to the personal data we hold about you.</p>
          <p>
            Rectification: Request corrections to your personal data if it is
            inaccurate or incomplete.
          </p>
          <p>Deletion: Request the deletion of your personal data.</p>
        </div>
      </div>
      <div className="space-y-2.5">
        <h5 className="font-bold text-xl md:text-2xl">Contact Us</h5>
        <div className="space-y-1.5">
          <p>
            If you have any questions about this Privacy Policy or wish to
            exercise your data protection rights, please contact us at
            contact@coolidgesolutions.com.
          </p>
          <h5 className="font-bold text-xl md:text-2xl">
            Changes to this Privacy Policy
          </h5>
          <p>
            We may update this Privacy Policy to reflect changes in our data
            handling practices or for legal, regulatory, or operational reasons.
            Please check this page periodically for updates.
          </p>
          <p>
            By using our "Contact Us" form, you agree to the terms of this
            Privacy Policy. Last Updated: 24/10/2023
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyBody;
