import React from "react";
import cybersecurity_transformation_hero_img from "../../assets/images/cybersecurity-transformation-hero-img.png";

const CybersecurityTransformationHero = () => {
  return (
    <div>
      <div className="relative  py-10 xl:py-20">
        <div className="container mx-auto space-y-12 xl:space-y-36">
          <div className="flex-flex-col space-y-4 items-end xl:space-y-0 xl:flex">
            <div className="w-full xl:w-[63%] space-y-4 xl:space-y-8">
              <div className="flex justify-center xl:justify-start">
                <h1 className="max-w-4xl text-3xl xl:text-7xl text-white font-source-sans-pro-light">
                  Cybersecurity <br /> Transformation
                </h1>
              </div>
              <p className="xl:max-w-xl text-sm xl:text-[18px] xl:leading-8  text-white  text-center xl:text-left font-source-sans-pro-extralight px-[1rem] md:px-6 lg:px-0">
              The world is interconnected, and traditional security measures fall short of protecting your organisation effectively. Our cybersecurity transformation services, guided by seasoned experts, meticulously assess your infrastructure, crafting customised strategies and implementing state-of-the-art defences. These services provide a robust approach to fortify your digital assets, ensuring you can stay resilient in the face of ever-evolving cyber threats.
              </p>
              <p className="xl:max-w-xl text-sm xl:text-[18px] xl:leading-8  text-white  text-center xl:text-left font-source-sans-pro-extralight px-[1rem] md:px-6 lg:px-0">
                We are dedicated to empowering your team through robust training and cultivating a cybersecurity culture that places a strong emphasis on multi-layered protection. We foster growth, where your organisation's assets are not only safeguarded but also positioned for resilience in the ever-changing digital world. With our holistic approach, you can have confidence in the knowledge that your team is well-prepared and your systems are fortified to meet the challenges of the digital age, ensuring the long-term security and integrity of your valuable assets.
              </p>
            </div>
            <div className="w-full xl:w-[37%] xl:absolute xl:right-0">
              <img
                src={cybersecurity_transformation_hero_img}
                className="w-fit"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CybersecurityTransformationHero;
