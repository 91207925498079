import React from "react";
import { useState } from "react";

const CustomBtn = ({
  text,
  green,
  boxHover,
  boxBeforeHover,
  hoverTextColor,
  textColorBeforHover,
  hoverBackgroundColor,
  unHoverBackgroundColor,
  paddingTop,
  paddingBottom,
  paddingLeft,
  paddingRight,
  onClick,
  disabled,
  className,
}) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`${className} font-source-sans-pro-regular`}
      style={{
        fontWeight: "100",
        border: isHovering ? "1px solid " : "1px solid ",
        boxShadow: isHovering ? boxHover : boxBeforeHover,
        paddingTop: paddingTop,
        paddingBottom: paddingBottom,
        paddingLeft: paddingLeft,
        paddingRight: paddingRight,
        lineHeight: "1",
        transition: "0.30s",
        textTransform: "uppercase",
        backgroundColor: isHovering
          ? hoverBackgroundColor
          : unHoverBackgroundColor,
        color: isHovering ? hoverTextColor : textColorBeforHover,
        letterSpacing: "6px",
        fontSize: "14px",
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {text}
    </button>
  );
};

export default CustomBtn;
