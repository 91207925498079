import React, { useRef } from "react";
import Navbar from "../components/connected/Navbar";
import Footer from "../components/sections/Footer";
import PrivacyPolicyBody from "../components/sections/PrivacyPolicyBody";
const PrivacyPolicy = () => {
  const aboutUsRef = useRef(null);
  const ourServicesRef = useRef(null);

  const scrollToAboutUs = () => {
    if (aboutUsRef.current) {
      aboutUsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToOurServices = () => {
    if (ourServicesRef.current) {
      ourServicesRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      <div className="bg-#391647">
        <Navbar />
      </div>
      <PrivacyPolicyBody />
      <Footer
        scrollToAboutUs={scrollToAboutUs}
        scrollToOurServices={scrollToOurServices}
      />
    </div>
  );
};

export default PrivacyPolicy;
