import React from "react";
import Navbar from "../components/connected/Navbar";
import Footer from "../components/sections/Footer";
import ScrollToTopBtn from "../components/connected/ScrollToTopBtn";
import BlogPosts from "./BlogPosts";

const Blog = () => {
  return (
    <div>
      <div className="">
        <Navbar />
      </div>
      <BlogPosts />
      <Footer />
      <ScrollToTopBtn />
    </div>
  );
};

export default Blog;
