import React, { useState, useEffect } from "react";

const ScrollToTopBtn = () => {
  const [isButtonVisible, setIsButtonVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsButtonVisible(true);
    } else {
      setIsButtonVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`sticky w-full flex justify-end bottom-10 pb-3 pr-5 lg:pr-16 transition ${
        isButtonVisible ? "" : "invisible"
      }`}
    >
      <div
        onClick={scrollToTop}
        className="bg-SmokeyGrey px-3.5 pt-1 pb-2 flex items-center justify-center z-20 cursor-pointer text-gray-400 hover:text-white transition rounded-full"
      >
        <button className="text-2xl">&#8911;</button>
      </div>
    </div>
  );
};

export default ScrollToTopBtn;
