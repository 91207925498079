import React from "react";
import strategy_and_risk_body_img from "../../assets/images/strategy-and-risk-body-img.png";
import CustomBtn from "../primitive/CustomBtn";
import { useNavigate } from "react-router-dom";

const StrategyAndRiskBody = () => {
  const navigate = useNavigate();

  const navigateToContactUs = () => {
    navigate("/contact-us");
  };
  return (
    <div className="relative strategy_and_risk_body_bg py-10 lg:py-24">
      <div className="container mx-auto space-y-12 lg:space-y-36">
        <div className="flex-flex-col space-y-4 items-center lg:space-y-0 lg:flex">
          <div className="w-full lg:w-[63%]">
            <p className="lg:max-w-xl text-sm lg:text-[18px] lg:leading-8  text-white  text-center lg:text-left font-source-sans-pro-extralight px-[1rem] md:px-6 lg:px-0">
              Based on our findings, we develop tailored cybersecurity
              strategies that align with your business goals, regulatory
              requirements, and industry best practices. Our approach
              encompasses a multi-layered defence framework, combining advanced
              technologies, robust policies, and comprehensive employee training
              to mitigate risks effectively. Our risk management services go
              beyond the identification of vulnerabilities. We work closely with
              your team to prioritise and address risks, creating a roadmap for
              remediation and continuous improvement. By implementing proactive
              measures, such as threat intelligence monitoring and incident
              response planning, we help you stay ahead of emerging threats and
              swiftly respond to any incidents that may occur. Throughout the
              process, we emphasise collaboration and knowledge transfer,
              equipping your organisation with the skills and understanding
              necessary to maintain a strong cybersecurity posture long-term.
              Our goal is to empower you to make informed decisions, adapt to
              evolving threats, and instil a culture of security awareness among
              your employees.
            </p>
          </div>
          <div className="w-full lg:w-[37%] lg:absolute lg:right-0">
            <img src={strategy_and_risk_body_img} className="w-full" alt="" />
          </div>
        </div>
        <div className="flex flex-col space-y-16 justify-center">
          <p className="lg:max-w-4xl text-base md:text-[24px] md:leading-10 text-white text-center font-source-sans-pro-extralight px-[1rem] md:px-6 lg:px-0">
            Coolidge Solutions is your trusted advisor for your risk and
            compliance journey, we provide a unified and simplified process
            which identifies areas of improvement in your cybersecurity posture
            and tailors your cybersecurity strategy to achieve your business
            goals and comply with recognized international standards such as
            NIST CSF, SOC 1 and SOC 2, PCI-DSS, ISO 27001, Canada's ITG-33, and
            OSFI B-10.
          </p>
          <div className="flex justify-center">
            <CustomBtn
              onClick={navigateToContactUs}
              text="Request a Service"
              green="none"
              boxHover="0 0 0 0 #000000, inset 6em 4.5em 0 0 #000000"
              boxBeforeHover="0.3em 0.3em 0 0 #000000, inset 0.3em 0.3em 0 0 #000000"
              hoverTextColor="#FFFFFF"
              textColorBeforHover="#000000"
              hoverBackgroundColor="#ffffff"
              unHoverBackgroundColor="#ffffff"
              paddingTop="1.2rem"
              paddingBottom="1.2rem"
              paddingLeft="2rem"
              paddingRight="2rem"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StrategyAndRiskBody;