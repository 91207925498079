import React from "react";
import { Link } from "react-router-dom";
import ReduceText from "../../utils/ReduceText";
import dateFormatter from "../../utils/dateFormatter";
import ReactHtmlParser from "html-react-parser";

const BlogPostCard = ({ post }) => {
  const MAX_LENGTH = 200;

  const description = post?.description || "";

  const truncatedDescription =
    description.length > MAX_LENGTH
      ? description.substring(0, MAX_LENGTH) + "..."
      : description;

  const title = post?.title;
  const slug = title
    ?.toLowerCase()
    ?.replace(/[^\w\s]/g, "")
    ?.replace(/\s+/g, "_");
  // const slug = title?.toLowerCase()?.replace(/\s+/g, "_");
  return (
    <div className="rounded-3xl shadow">
      <div className="relative rounded-t-3xl">
        <img
          src={post.file}
          alt=""
          className="rounded-t-3xl w-full h-64 object-cover"
        />
        <span
          className="font-source-sans-pro-extralight text-xs bg-white py-1 px-2 rounded-lg absolute top-4
         right-4"
        >
          {post.label === 1 ? "Politics" : post.label === 2 ? "Education" : ""}
        </span>
      </div>
      <div className="p-4 space-y-4">
        <p className="text-base md:text-xl h-16">{ReduceText(post?.title)}</p>
        <div className="font-source-sans-pro-extralight text-[10px] md:text-sm lg:h-32">
          {ReactHtmlParser(truncatedDescription)}
        </div>
        <div className="flex items-center justify-between">
          <Link
            to={`/blog/${post.id}/${slug}`}
            className="text-xs md:text-sm px-3 py-2 rounded-xl bg-VeryLightPurple"
          >
            Read More
          </Link>
          <p className="text-xs md:text-sm font-source-sans-pro-extralight">
            {dateFormatter(post.created_at)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default BlogPostCard;
