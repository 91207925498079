import React from "react";
import cybersicurity_testing_body_img from "../../assets/images/cybersicurity_testing_body_img.png";
import CustomBtn from "../primitive/CustomBtn";
import { useNavigate } from "react-router-dom";

const CybersecurityTestingBody = () => {
  const navigate = useNavigate();

  const navigateToContactUs = () => {
    navigate("/contact-us");
  };

  return (
    <div className="cybersecurity_testing_body_bg_img pb-20 md:pb-32">
      <div className=" flex flex-col-reverse items-center space-y-6 md:space-y-0 md:flex-row md:py-20 md:space-x-20">
        <div className="w-full md:w-[45%]">
          <img src={cybersicurity_testing_body_img} className="w-full" alt="" />
        </div>
        <div className="px-1.5 nd:px-0 w-full md:w-[55%] space-y-2.5 md:space-y-4 pb-12">
          <p className="md:max-w-xl text-base md:text-[16px] md:leading-8  text-white text-center md:text-left font-source-sans-pro-extralight px-[1rem] md:px-6 lg:px-0">
            Our approach also delivers a comprehensive evaluation of your security posture in the face of ever-evolving cyber threats. While we have re-established that  in the world  of cybersecurity, the conventional approaches are no longer sufficient, it is also important to buttress that Cyber threats have become highly adaptive, which is why we leverage the latest tools and techniques. Our arsenal includes automated scanning, social engineering simulations, and advanced threat modelling. These tactics enhance our capabilities to stay ahead in the perpetual cat-and-mouse game with cyber threats.
          </p>
          <p className="md:max-w-xl text-base md:text-[16px] md:leading-8  text-white text-center md:text-left font-source-sans-pro-extralight px-[1rem] md:px-6 lg:px-0">
            In this partnership with us, you'll gain a distinct advantage in your battle against cyber threats. You will receive a detailed report outlining areas for improvement and actionable recommendations to mitigate risks. This intelligence empowers you to make informed decisions, efficiently allocating resources to strengthen your defences against known and emerging cyber threats.
          </p>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center px-1.5 nd:px-0 space-y-3 md:space-y-20">
        <p className="md:max-w-2xl text-base md:text-[16px] md:leading-7  text-white text-center font-source-sans-pro-extralight px-[1rem] md:px-6 lg:px-0 py-6 md:py-0">
          Don’t wait for a cyber incident to expose your vulnerabilities. Take
          proactive steps to safeguard your organization with our trusted
          cybersecurity testing services. Together, we can stay one step ahead
          of cyber threats and ensure the resilience of your digital assets.
        </p>
        <div>
          <CustomBtn
            onClick={navigateToContactUs}
            text="Request a Service"
            green="none"
            boxHover="0 0 0 0 #000000, inset 6em 4.5em 0 0 #000000"
            boxBeforeHover="0.3em 0.3em 0 0 #FFFFFF, inset 0.3em 0.3em 0 0 #FFFFFF"
            hoverTextColor="#FFFFFF"
            textColorBeforHover="#000000"
            hoverBackgroundColor="#FFFFFF"
            unHoverBackgroundColor="#FFFFFF"
            paddingTop="1.2rem"
            paddingBottom="1.2rem"
            paddingLeft="2rem"
            paddingRight="2rem"
          />
        </div>
      </div>
    </div>
  );
};

export default CybersecurityTestingBody;
