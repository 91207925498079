import React from "react";
import cs_logo from "../../assets/icons/cs-logo.svg";
import cs_footer_log from "../../assets/icons/cs-footer-logo.svg";

import { Link, useLocation, useNavigate } from "react-router-dom";
import CustomBtn from "../primitive/CustomBtn";
import MobileMenu from "./MobileMenu";

const Navbar = () => {
  const location = useLocation();

  const navigate = useNavigate();

  const navigateToContactUs = () => {
    navigate("/contact-us");
  };

  // const openPopup = () => {
  //   const popupWidth = 600;
  //   const popupHeight = 600;
  //   const popupFeatures = `width=${popupWidth},height=${popupHeight},scrollbars=no,resizable=no`;

  //   window.open(
  //     "https://medium.com/@coolidgesolutions",
  //     "popup",
  //     popupFeatures
  //   ); // eslint-disable-next-line
  // };

  const isBlogPostDetails = location.pathname.startsWith("/blog");

  return (
    <div
      className={
        location.pathname === "/"
          ? "md:mx-24 border-b-[0.1px] border-b-white"
          : "md:mx-24 border-b-[0.1px] border-b-Liver"
      }
    >
      <div className="pt-1">
        <div className="flex items-center px-[1.5rem] md:px-10 justify-between py-2.5">
          <Link to="/">
            {location.pathname === "/privacy-policy" || isBlogPostDetails ? (
              <img src={cs_footer_log} className="w-28" alt="" />
            ) : (
              <img src={cs_logo} alt="" />
            )}
          </Link>
          <div className="xl:hidden">
            <MobileMenu />
          </div>
          <div className="hidden xl:block">
            <div className="flex items-center space-x-2 xl:space-x-8">
              <div
                className={
                  location.pathname === "/privacy-policy" || isBlogPostDetails
                    ? "flex items-center space-x-4 lg:space-x-6 text-black font-source-sans-pro-extralight text-[14px]"
                    : "flex items-center space-x-4 lg:space-x-6 text-white font-source-sans-pro-extralight text-[14px]"
                }
              >
                <Link
                  to="/"
                  className={
                    location.pathname === "/"
                      ? "raise border-b-[0.1px]"
                      : "raise"
                  }
                >
                  Home
                </Link>
                <Link
                  to="/strategy-and-risk"
                  className={
                    location.pathname === "/strategy-and-risk"
                      ? "raise border-b-[0.1px]"
                      : "raise"
                  }
                >
                  Strategy and Risk Management
                </Link>
                <Link
                  to="/cybersecurity-transformation"
                  className={
                    location.pathname === "/cybersecurity-transformation"
                      ? "raise border-b-[0.1px]"
                      : "raise"
                  }
                >
                  Cybersecurity Transformation
                </Link>
                <Link
                  to="/cybersecurity-testing"
                  className={
                    location.pathname === "/cybersecurity-testing"
                      ? "raise border-b-[0.1px]"
                      : "raise"
                  }
                >
                  Cybersecurity Testing
                </Link>
                <Link
                  to="/data-analytics"
                  className={
                    location.pathname === "/data-analytics"
                      ? "raise border-b-[0.1px]"
                      : "raise"
                  }
                >
                  Data Analytics
                </Link>
                <Link
                  to="/blog"
                  className={
                    location.pathname === "/blog"
                      ? "raise border-b-[0.1px]"
                      : "raise"
                  }
                >
                  Article
                </Link>
                {/* <button onClick={openPopup}>Article</button> */}
              </div>
              <div className="-mb-3">
                <CustomBtn
                  onClick={navigateToContactUs}
                  text="Request a service"
                  green="none"
                  boxHover="0 0 0 0 #FFFFFF, inset 6em 4.5em 0 0 #FFFFFF"
                  boxBeforeHover="0.3em 0.3em 0 0 #FFFFFF, inset 0.3em 0.3em 0 0 #FFFFFF"
                  hoverTextColor=" #000000"
                  textColorBeforHover="#000000"
                  hoverBackgroundColor="#000000"
                  unHoverBackgroundColor="white"
                  paddingTop="1.2rem"
                  paddingBottom="1.2rem"
                  paddingLeft="1rem"
                  paddingRight="1rem"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
